export async function paginate (api, baseUrl, limit, offset, params) {
  const data = []
  for await (const result of getPaginated(api, baseUrl, limit, offset, params)) {
    data.push(result)
  }
  return { data }
}

async function* getPaginated (api, baseUrl, limit, offset, queryParams) {
  let page = 1

  let config = {
    params: queryParams
  }

  while (true) {
    if (page > 1) {
      offset = (page * limit) - limit
    }
    const url = `${baseUrl}/${limit}/${offset}`
    const res = await api(url, config)

    if (!res.data || res.data.length === 0) {
      break
    }
    for (const result of res.data) {
      yield result
    }
    if (res.data.length < limit) break
    page++
  }
}
