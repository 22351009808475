import Vue from 'vue'
import VueRouter from 'vue-router'

const Parties = () => import('@/views/parties.vue')
const PartiesEdit = () => import('@/views/parties_edit.vue')

// authentication guard
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Parties,
    meta: {
      requiresAuth: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/parties',
    name: 'Parties',
    component: Parties,
    meta: {
      requiresAuth: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/parties_edit',
    name: 'Parties Edit',
    component: PartiesEdit,
    meta: {
      requiresAuth: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/parties_create',
    name: 'Parties Create',
    component: PartiesEdit,
    meta: {
      requiresAuth: true,
      requiresWriteAccess: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/attibute',
    name: 'attribute',
    component: () => import('@/components/admin/party_attribute_edit.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/relationship',
    name: 'relationship',
    component: () => import('@/components/admin/relationship_type_edit.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Customer Management'
    }
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/subscription.vue'),
    meta: {
      requiresAuth: true,
      title: 'Subscription Management'
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/errors/403'),
    meta: {
      requiresAuth: true,
      menuItem: false,
      title: '403 - Forbidden',
      adminOnly: false
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/errors/404'),
    meta: {
      requiresAuth: true,
      menuItem: false,
      title: '404 - Not Found',
      adminOnly: false
    }
  },
  {
    path: '/503',
    name: '503',
    component: () => import('@/views/errors/503'),
    meta: {
      requiresAuth: true,
      menuItem: false,
      title: '503 - Service Unavailable',
      adminOnly: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const guardOptions = {
  entities: ['party'],
  adminEntities: ['party', 'key', 'key_value', 'relationship_type'],
  writeActions: ['create', 'read', 'update']
}

router.beforeResolve(async (to, from, next) => {
  to.meta.referrer = from.name
  // Use the route's "meta.title" value to assign the page's title
  if (to?.meta?.title) document.title = to.meta.title
  // For routes requiring authentication ( has "meta.requiresAuth" property )
  if (to.meta.requiresAuth === true) {
    return authGuard(to, from, next, guardOptions)
  }
  return next()
})

export default router
