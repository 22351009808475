import disabledFields from '../config/disabled_fields'

const config = {
  date_display: 'MM/DD/YYYY',
  api_date_format: 'YYYY-MM-DD',
  timestamp: 'MM/DD/YYYY - h:mm:ss A',
  disabled_fields: disabledFields,
  currentDate: new Date().toISOString().substr(0, 10)
}

export default config
