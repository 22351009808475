var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticStyle: { top: "25px" },
      attrs: {
        top: "",
        "content-class": "pa-0",
        color: _vm.alertType,
        timeout: _vm.alertType !== "success" ? -1 : _vm.timeout
      },
      model: {
        value: _vm.alert,
        callback: function($$v) {
          _vm.alert = $$v
        },
        expression: "alert"
      }
    },
    [
      _vm.attrsError
        ? _c(
            "v-alert",
            {
              staticClass: "my-0",
              attrs: {
                dense: "",
                border: "left",
                prominent: "",
                type: "warning",
                "retain-focus": ""
              },
              model: {
                value: _vm.alert,
                callback: function($$v) {
                  _vm.alert = $$v
                },
                expression: "alert"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              on: { click: _vm.attrsConfirm }
                            },
                            [_vm._v(" Confirm ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "" },
                              on: { click: _vm.attrsCancel }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-alert",
            {
              staticClass: "my-0",
              attrs: {
                dense: "",
                border: "left",
                dismissible: "",
                prominent: _vm.alertType !== "success",
                type: _vm.alertType,
                timeout: _vm.timeout,
                "retain-focus": ""
              },
              model: {
                value: _vm.alert,
                callback: function($$v) {
                  _vm.alert = $$v
                },
                expression: "alert"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.alertMessage) + " "),
              _vm.messages && _vm.messages.length > 0
                ? _vm._l(_vm.messages, function(message, key) {
                    return _c("div", { key: key, staticClass: "errorItem" }, [
                      _vm._v(" " + _vm._s(message) + " ")
                    ])
                  })
                : _vm._e(),
              _vm.errors && _vm.errors.length > 0
                ? _vm._l(_vm.errors, function(error, key) {
                    return _c("div", { key: key, staticClass: "errorItem" }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  })
                : _vm._e()
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }