<template>
  <v-overlay v-if="$auth.isLoading || !$auth.isAuthenticated">
    <v-progress-circular
      indeterminate
      size="64">
    </v-progress-circular>
  </v-overlay>
  <v-app v-else id="inspire">
    <v-app-bar
      app
      color="main"
      dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mx-auto"/>
      <router-link :to="{ path: '/'}">
            <v-img v-if="tenantsWithLogos.includes(`${$auth.tenant}`)"
              max-height="110"  
              max-width="110"
              class="ml-6"
              :src="require(`@/assets/${$auth.tenant}_logo.png`)" 
              :alt="`${$auth.tenant} Logo`"> 
            </v-img>
          <v-app-bar-nav-icon v-else>
            <v-icon color="#fff">mdi-home</v-icon>
          </v-app-bar-nav-icon>
        </router-link>
      <v-spacer></v-spacer>
      <VideoMenu v-if="$auth.tenant === 'awg'" />
      <v-col cols="auto" class="pr-0">
        <PortalNavMenu active="customer" />
      </v-col>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app>
      <v-list nav>
        <v-list-item :to="{ name: 'Parties'}" link>
          <v-list-item-action>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Parties </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isAdmin" :to="{ name: 'admin'}" link>
          <v-list-item-action>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Admin </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasScope('subscription:read')" :to="{ name: 'subscription'}" link>
          <v-list-item-action>
            <v-icon>mdi-vector-combine</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Subscriptions </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isAdmin" @click="downloadStores">
          <v-list-item-action>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Download Store List </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isAdmin && $auth.tenant === 'jbg'" @click="downloadUsers">
          <v-list-item-action>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Download User List </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="[(!miniVariant) ? 'pa-2': 'pa-1']">
          <v-btn block id="logoutBtn" v-bind:style="styleObject" @click.prevent="logout()">
            <v-icon color="#fff">mdi-logout</v-icon>
            <span v-if="!miniVariant">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <Maintenance v-if="maintenanceStatus" />

    <v-main v-else style="background-color: #F2F2F2">
      <AlertBar></AlertBar>
      <router-view v-if="hasPortalAccess" />
      <v-alert
        v-else
        type="error"
        text
        border="left">
        You don't have authorized permissions to access this site.
      </v-alert>
    </v-main>
    <v-footer
      color="main"
      app
      class="justify-space-between">
      <span class="white--text"> iPro <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy; {{ new Date().getFullYear() }} </span>
      <span class="white--text">v. {{ version }}</span>
    </v-footer>
  </v-app>
</template>
<script>
// api
import Party from '@/axios/party-endpoint'
// components
import AlertBar from '@/components/AlertBar.vue'
const Maintenance = () => import('@/components/Maintenance.vue')
const VideoMenu = () => import('@/components/VideoMenu.vue')
const PortalNavMenu = () => import('@/components/PortalNavMenu.vue')
// mixins
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'App',
  data () {
    return {
      drawer: false,
      miniVariant: false,
      styleObject: {
        background: '#E53935',
        color: 'white',
        fontSize: '15px'
      },
      storedKeys: ['party_search_filters']
    }
  },
  mixins: [userAccess, displayAlert],
  components: { AlertBar, Maintenance, VideoMenu, PortalNavMenu },
  computed: {
    version () {
      return process.env.VUE_APP_VERSION
    },
    maintenanceStatus () {
      return JSON.parse(process.env.VUE_APP_MAINTENANCE_STATUS)
    },
    tenantsWithLogos() {
      return [        
        'awg',
        'alliance-retail-group'      
      ]    
    },
  },
  methods: {
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
      for (const key of this.storedKeys) {
        sessionStorage.removeItem(key)
      }
    },
    async downloadStores () {
      try {
        const res = await Party.downloadStores()
        if (res?.data) {
          location.href = res.data
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async downloadUsers () {
      try {
        const res = await Party.downloadUsers()
        if (res?.data) {
          location.href = res.data
        }
      } catch (err) {
        this.handleError(err)
      }
    },
  }
}
</script>
<style lang="scss">
.v-text-field__slot input::-webkit-calendar-picker-indicator {
  display: none;
}

.v-text-field__slot input[type="date"] {
  cursor: text;
}

.v-text-field__slot input[type="date"]::-webkit-input-placeholder {
  visibility: hidden;
}
span.registeredTrademark {
  position: relative;
  top: -.3rem;
  font-size: .5rem;
  left: -0.05rem;
}

footer {
  font-size: small;
}
</style>
