import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Party {
  constructor () {
    const party = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.party = party
  }

  async searchParties (data = {}, offset = 0, limit = 250) {
    await AuthToken.setAuth(this.party)
    return this.party({
      url: 'v1/party/search_parties',
      method: 'POST',
      data,
      params: { offset, limit }
    })
  }

  async post (data) {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: 'v1/party',
      method: 'POST',
      data: data
    })
  }

  async put (data, uuid) {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: `v1/party/${uuid}`,
      method: 'PUT',
      data: data
    })
  }

  async passwordReset(payload) {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: 'v1/auth0_password_reset',
      method: 'POST',
      params: payload
    })
  }

  async auth0AccessChange(payload) {
    await AuthToken.setAuth(this.party)
    return this.party({
      url: 'v1/toggle_user_access',
      method: 'PUT',
      params: payload
    })
  }

  async getUserStatus(payload) {
    await AuthToken.setAuth(this.party)
    return this.party({
      url: 'v1/auth0_blocked_info',
      method: 'GET',
      params: payload
    })
  }


  async end (data, uuid, date) {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: `v1/party_expire/${uuid}?end_date=${date}`,
      method: 'PUT',
      data: data
    })
  }

  async get (uuid) {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: `v1/party/${uuid}`,
      method: 'GET'
    })
  }

  async downloadStores () {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: `v1/stores_csv`,
      method: 'GET'
    })
  }

  async getUserProfile (email, include_stores = true) {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: 'v1/user_profile',
      method: 'GET',
      params: { email, include_stores }
    })
  }

  async downloadUsers () {
    await AuthToken.setAuth(this.party)

    return this.party({
      url: `v1/users_csv`,
      method: 'GET'
    })
  }
}

export default new Party()
