var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$auth.isLoading || !_vm.$auth.isAuthenticated
    ? _c(
        "v-overlay",
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    : _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-app-bar",
            { attrs: { app: "", color: "main", dark: "" } },
            [
              _c("v-app-bar-nav-icon", {
                staticClass: "mx-auto",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              }),
              _c(
                "router-link",
                { attrs: { to: { path: "/" } } },
                [
                  _vm.tenantsWithLogos.includes("" + _vm.$auth.tenant)
                    ? _c("v-img", {
                        staticClass: "ml-6",
                        attrs: {
                          "max-height": "110",
                          "max-width": "110",
                          src: require("@/assets/" +
                            _vm.$auth.tenant +
                            "_logo.png"),
                          alt: _vm.$auth.tenant + " Logo"
                        }
                      })
                    : _c(
                        "v-app-bar-nav-icon",
                        [
                          _c("v-icon", { attrs: { color: "#fff" } }, [
                            _vm._v("mdi-home")
                          ])
                        ],
                        1
                      )
                ],
                1
              ),
              _c("v-spacer"),
              _vm.$auth.tenant === "awg" ? _c("VideoMenu") : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "auto" } },
                [_c("PortalNavMenu", { attrs: { active: "customer" } })],
                1
              )
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: { app: "" },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c("v-divider"),
                      _c(
                        "div",
                        { class: [!_vm.miniVariant ? "pa-2" : "pa-1"] },
                        [
                          _c(
                            "v-btn",
                            {
                              style: _vm.styleObject,
                              attrs: { block: "", id: "logoutBtn" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.logout()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "#fff" } }, [
                                _vm._v("mdi-logout")
                              ]),
                              !_vm.miniVariant
                                ? _c("span", [_vm._v("Logout")])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { nav: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Parties" }, link: "" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("mdi-account-group")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(" Parties ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { to: { name: "admin" }, link: "" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-cog-outline")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v(" Admin ")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasScope("subscription:read")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: { name: "subscription" }, link: "" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-vector-combine")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" Subscriptions ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c(
                        "v-list-item",
                        { on: { click: _vm.downloadStores } },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-download-outline")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" Download Store List ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin && _vm.$auth.tenant === "jbg"
                    ? _c(
                        "v-list-item",
                        { on: { click: _vm.downloadUsers } },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-download-outline")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" Download User List ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.maintenanceStatus
            ? _c("Maintenance")
            : _c(
                "v-main",
                { staticStyle: { "background-color": "#F2F2F2" } },
                [
                  _c("AlertBar"),
                  _vm.hasPortalAccess
                    ? _c("router-view")
                    : _c(
                        "v-alert",
                        { attrs: { type: "error", text: "", border: "left" } },
                        [
                          _vm._v(
                            " You don't have authorized permissions to access this site. "
                          )
                        ]
                      )
                ],
                1
              ),
          _c(
            "v-footer",
            {
              staticClass: "justify-space-between",
              attrs: { color: "main", app: "" }
            },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v(" iPro "),
                _c("span", { staticClass: "registeredTrademark" }, [
                  _vm._v("®")
                ]),
                _vm._v(
                  " Systems, LLC. © " + _vm._s(new Date().getFullYear()) + " "
                )
              ]),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("v. " + _vm._s(_vm.version))
              ])
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }