import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class RelationshipType {
  constructor () {
    const relationship = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.relationship = relationship
  }

  async post (data) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: 'v1/relationship_type',
      method: 'POST',
      data: data
    })
  }

  async get (id) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: `v1/relationship_type/${id}`,
      method: 'GET'
    })
  }
  async getList (limit, offset, paging = true) {
    await AuthToken.setAuth(this.relationship)

    if (paging) {
      const url = 'v1/relationship_type'
      return paginate(this.relationship, url, limit, offset)
    }
    return this.relationship({
      url: `v1/relationship_type/${limit}/${offset}`,
      method: 'GET'
    })
  }

  async put (id, data) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: `v1/relationship_type/${id}`,
      method: 'PUT',
      data: data
    })
  }
}

export default new RelationshipType()
