<template>
  <v-snackbar
    top
    v-model="alert"
    content-class="pa-0"
    :color="alertType"
    :timeout="alertType !== 'success' ? -1 : timeout"
    style="top:25px">
      <v-alert
        v-if="attrsError"
        v-model="alert"
        dense
        class="my-0"
        border="left"
        prominent
        type="warning"
        retain-focus>
          <v-row align="center">
            <v-col>
              {{ alertMessage }}
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <span>
                <v-btn class="mr-4" @click="attrsConfirm">
                  Confirm
                </v-btn>
                <v-btn outlined @click="attrsCancel">
                  Cancel
                </v-btn>
              </span>
            </v-col>
          </v-row>
      </v-alert>
      <v-alert v-else
        v-model="alert"
        dense
        class="my-0"
        border="left"
        dismissible
        :prominent="alertType !== 'success'"
        :type="alertType"
        :timeout="timeout"
        retain-focus>
          {{ alertMessage }}
          <template v-if="messages && messages.length > 0">
            <div class="errorItem" v-for="(message, key) in messages" :key="key">
              {{ message }}
            </div>
          </template>
          <template v-if="errors && errors.length > 0">
            <div class="errorItem" v-for="(error, key) in errors" :key="key">
              {{ error }}
            </div>
          </template>
      </v-alert>
  </v-snackbar>
</template>
<script>
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'AlertBar',
  mixins: [displayAlert],
  created () {
    this.$root.$on('alertdialog', (data) => {
      this.alert = data.alert
      this.alertType = data.alertType
      this.alertMessage = data.alertMessage
      this.messages = data.messages
      this.attrsError = data.attrsError
    })
    this.$root.$on('nextFn', data => {
      this.nextFn = data
    })
  },
  methods: {
    attrsConfirm () {
      this.alert = false
      if (this.nextFn) {
        this.nextFn()
      }
    },
    attrsCancel () {
      this.attrsError = false
      this.alert = false
    }
  }
}
</script>
