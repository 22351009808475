import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import App from './App.vue'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'

// Config
import config from './config/party_config'

// Sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import VueRouter from 'vue-router'
const { isNavigationFailure } = VueRouter

// Auth0
import { domain, clientId, audience } from './auth/auth0-config.js'
import { Auth0Plugin } from './auth/auth0-plugin'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

let appEnv = process.env.VUE_APP_ENV

// local .env file is ignored by git in this project
if (appEnv === undefined) appEnv = 'local'

if (appEnv !== 'local') {
  Sentry.init({
    Vue: Vue,
    environment: appEnv,
    dsn: 'https://5188780f4d344755a818220119cf630e@o461793.ingest.sentry.io/5667222',
    integrations: [new Integrations.BrowserTracing()],
    tracingOptions: {
      trackComponents: true
    },
    logErrors: true,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'createRouterError'
    ],
    beforeSend (event, hint) {
      const error = hint.originalException
      if (error) {
        if (isNavigationFailure(error)) {
          return null
        }
        if (error.response) {
          event.extra = {
            error: error,
            response: error.response
          }
        }
      }
      return event
    }
  })
}

export const eventbus = new Vue()

Vue.config.productionTip = false

Vue.use(VueTelInput)

Vue.prototype.eventbus = eventbus
Vue.prototype.moment = moment
Vue.prototype.$config = config

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
