import Vue from 'vue'
import Vuex from 'vuex'
import { getInstance } from '@/auth/auth0-plugin'
import kv from '@/axios/key-values-endpoint'
import RelationshipType from '@/axios/party-relationship-type-endpoint'
import Party from '@/axios/party-endpoint'

Vue.use(Vuex)

const state = {
  party_types: [],
  relationship_types: [],
  phone_types: [],
  address_types: [],
  countries: [],
  state_provinces: [],
  userRelatedParties: []
}

const mutations = {
  setPartyTypes (state, payload) {
    state.party_types = payload
  },
  setRelationshipTypes (state, payload) {
    state.relationship_types = payload
  },
  setPhoneTypes (state, payload) {
    state.phone_types = payload
  },
  setAddressTypes (state, payload) {
    state.address_types = payload
  },
  setCountries (state, payload) {
    state.countries = payload
  },
  setStateProvinces (state, payload) {
    state.state_provinces = payload
  },
  setUserRelatedParties (state, payload) {
    state.userRelatedParties = payload
  }
}

const actions = {
  async getKeyValues (context) {
    try {
      const promises = [
        context.dispatch('getPartyTypes'),
        context.dispatch('getRelationshipTypes'),
        context.dispatch('getPhoneTypes'),
        context.dispatch('getAddressTypes'),
        context.dispatch('getCountries'),
        context.dispatch('getStateProvinces'),
      ]
      const results = await Promise.allSettled(promises)
      const rejected = results.filter(r => r?.status === 'rejected')
      if (rejected.length > 0) throw rejected
    } catch (err) {
      console.error(err)
    }
  },
  async getPartyTypes (context) {
    if (context.getters.party_types.length === 0) {
      let partyTypes = []
      return kv.keyValues.getList('PARTY_TYPE', 100, 0).then(res => {
        if (res?.data?.length > 0) {
          partyTypes = res.data
        }
        return context.commit('setPartyTypes', partyTypes)
      }).catch(err => {
        throw err
      })
    }
  },
  async getRelationshipTypes (context) {
    if (context.getters.relationship_types.length === 0) {
      let relTypes = []
      return RelationshipType.getList(100, 0).then(res => {
        if (res?.data?.length > 0) {
          relTypes = res.data
        }
        return context.commit('setRelationshipTypes', relTypes)
      }).catch(err => {
        throw err
      })
    }
  },
  async getPhoneTypes (context) {
    if (context.getters.phone_types.length === 0) {
      let phoneTypes = []
      return kv.keyValues.getList('PHONE_TYPE', 100, 0).then(res => {
        if (res?.data?.length > 0) {
          phoneTypes = res.data
        }
        return context.commit('setPhoneTypes', phoneTypes)
      }).catch(err => {
        throw err
      })
    }
  },
  async getAddressTypes (context) {
    if (context.getters.address_types.length === 0) {
      let addressTypes = []
      return kv.keyValues.getList('ADDRESS_TYPE', 100, 0).then(res => {
        if (res?.data?.length > 0) {
          addressTypes = res.data
        }
        return context.commit('setAddressTypes', addressTypes)
      }).catch(err => {
        throw err
      })
    }
  },
  async getCountries (context) {
    if (context.getters.countries.length === 0) {
      let countries = []
      return kv.keyValues.getList('COUNTRY', 100, 0).then(res => {
        if (res?.data?.length > 0) {
          countries = res.data
        }
        return context.commit('setCountries', countries)
      }).catch(err => {
        throw err
      })
    }
  },
  async getStateProvinces (context) {
    if (context.getters.state_provinces.length === 0) {
      let stateProvinces = []
      return kv.keyValues.getList('STATE_PROVINCE_US', 100, 0).then(res => {
        if (res?.data?.length > 0) {
          stateProvinces = res.data
        }
        return context.commit('setStateProvinces', stateProvinces)
      }).catch(err => {
        throw err
      })
    }
  },
  async getUserRelatedParties (context) {
    const userParties = context.getters.userRelatedParties
    if (userParties.length > 0) return

    let relatedParties = []
    try {
      const res = await Party.getUserProfile(context.getters.email)
      if (res?.data?.length > 0) {
        relatedParties = res.data
      }
    } catch (err) {
      console.error(err)
    }
    return context.commit('setUserRelatedParties', relatedParties)
  }
}

const getters = {
  email () {
    const authService = getInstance()
    if (authService.isAuthenticated) {
      return authService.user.email
    }
    return ''
  },
  party_types (state) {
    return state.party_types
  },
  relationship_types (state) {
    return state.relationship_types
  },
  phone_types (state) {
    return state.phone_types
  },
  address_types (state) {
    return state.address_types
  },
  countries (state) {
    return state.countries
  },
  state_provinces (state) {
    return state.state_provinces
  },
  userRelatedParties (state) {
    return state.userRelatedParties
  }
}

export const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations
})
